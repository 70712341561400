/* eslint-disable eqeqeq */
/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable no-unused-vars */
/* eslint-disable unicorn/prefer-prototype-methods */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-undef */
/* eslint-disable no-else-return */
document.addEventListener('DOMContentLoaded', () => {
  const modal = document.querySelector('[data-js="modal"]')
  const modalInner = modal.querySelector('.modal__inner')
  const modalClose = document.querySelectorAll('[data-js="modal-close"]')
  const mobileBurger = document.querySelector('[data-js="toggle-mobile-menu"]')
  const mobileMenu = document.querySelector('[data-js="mobile-menu"]')
  const loading = document.querySelector('[data-js="loading"]')
  const sortButton = document.querySelector('[data-js="sort"]')
  const viewButtonBlock = document.querySelector('[data-js="view-block"]')
  const viewButtonTable = document.querySelector('[data-js="view-table"]')
  const contentWrapper = document.querySelector('[data-js="content-wrapper"]')
  const sendDataButton = document.querySelector('[data-js="modal-submit"]')
  const cookieMessage = document.querySelector('[data-js="cookie-msg"]')
  const cookieMessageClose = document.querySelectorAll('[data-js="cookie-msg-close"]')
  const openChat = document.querySelector('[data-js="chat-open-button"]')
  const closeChat = document.querySelector('[data-js="modal-close-chat"]')
  const chatModal = document.querySelector('[data-js="chat-modal"]')
  const chatSendDataButton = document.querySelector('[data-js="modal-submit-chat"]')

  const contentData = []
  let dataBase
  let view = 'table'
  let currentAcc

  if (window.localStorage.getItem('cookie') !== 'true') {
    cookieMessage.style.display = 'block'
    cookieMessageClose.forEach(el => el.addEventListener('click', () => {
      window.localStorage.setItem('cookie', true)
      cookieMessage.style.display = 'none'
    }))
  }

  const promise1 = getData('https://accs.biz/accounts')
  // const promise1 = getData('http://accs.biz/api/get-accounts')
  .then(data => {
    for (const [key, value] in data.data) {
      if (key) {
        contentData.push({
          id: data.data[key].id,
          avatar: data.data[key].avatar,
          name: data.data[key].name,
          adult: data.data[key].adult,
          postKarma: data.data[key].post_karma,
          commentKarma: data.data[key].comment_karma,
          price: data.data[key].price,
          cakeDay: data.data[key].cake_day,
          emailVerif: data.data[key].email_include,
          cqs: data.data[key].cqs,
          typeProxy: data.data[key].proxy,
          // status: data.data[key].status,
          showItem: true
        })
      }
    }
  })

  const promise2 = getData('./assets/source.json')
  .then(data => {
    chooseLang(data)
    dataBase = data
    return data
  })

  Promise.all([promise1, promise2])
  .then(data => {
    const language = window.localStorage.getItem('language')
    renderContentTable(data[1], language, contentData)
  })
  .then(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    loading.style.display = 'none'
  })

  // getData('http://accs.biz/api/accounts-param')
  getData('https://accs.biz/accounts-param')
  .then(params => {
    filter(params)
  })

  modal.addEventListener('click', event => {
    if (event.target === event.currentTarget) {
      modal.style.display = 'none'
      modalInner.classList.remove('modal_active')
    }
  })

  modalClose.forEach(el => el.addEventListener('click', () => {
    modal.style.display = 'none'
    modalInner.classList.remove('modal_active')
  }))

  openChat.addEventListener('click', () => {
    chatModal.classList.add('chat_active')
  })

  closeChat.addEventListener('click', () => {
    chatModal.classList.remove('chat_active')
  })

  chatSendDataButton.addEventListener('click', () => {
    sendChatData()
  })

  mobileBurger.addEventListener('click', e => {
    e.currentTarget.classList.toggle('mobile-burger_active')
    mobileMenu.classList.toggle('mobile-menu_active')
  })

  sortButton.addEventListener('change', e => {
    // eslint-disable-next-line default-case
    switch (e.currentTarget.value) {
      case 'standard': {
        contentData.sort((a, b) => a.id - b.id)
        break
      }

      case 'post-karma-max': {
        contentData.sort((a, b) => b.postKarma - a.postKarma)
        break
      }

      case 'post-karma-min': {
        contentData.sort((a, b) => a.postKarma - b.postKarma)
        break
      }

      case 'comment-karma-max': {
        contentData.sort((a, b) => b.commentKarma - a.commentKarma)
        break
      }

      case 'comment-karma-min': {
        contentData.sort((a, b) => a.commentKarma - b.commentKarma)
        break
      }

      case 'price-max': {
        contentData.sort((a, b) => b.price - a.price)
        break
      }

      case 'price-min': {
        contentData.sort((a, b) => a.price - b.price)
        break
      }
    }

    const language = window.localStorage.getItem('language')
    renderContentTable(dataBase, language, contentData)
  })

  const karmaSort = document.querySelector('[data-js="sort-karma"]')
  const commentSort = document.querySelector('[data-js="sort-comment"]')
  const priceSort = document.querySelector('[data-js="sort-price"]')

  karmaSort.addEventListener('click', event => sorting(event, 'postKarma'))
  commentSort.addEventListener('click', event => sorting(event, 'commentKarma'))
  priceSort.addEventListener('click', event => sorting(event, 'price'))

  function sorting(e, filteredField) {
    document.querySelectorAll('[data-js="sort-tools"] [data-sort-status]').forEach(el => {
      if (e.currentTarget !== el) {
        el.dataset.sortStatus = 'none'
        el.classList.remove('table-header__caption_min', 'table-header__caption_max')
      }
    })

    if (e.currentTarget.dataset.sortStatus === 'none') {
      contentData.sort((a, b) => a[filteredField] - b[filteredField])
      e.currentTarget.dataset.sortStatus = 'min'
      e.currentTarget.classList.add('table-header__caption_min')
    } else if (e.currentTarget.dataset.sortStatus === 'min') {
      contentData.sort((a, b) => b[filteredField] - a[filteredField])
      e.currentTarget.dataset.sortStatus = 'max'
      e.currentTarget.classList.add('table-header__caption_max')
      e.currentTarget.classList.remove('table-header__caption_min')
    } else {
      contentData.sort((a, b) => a.id - b.id)
      e.currentTarget.dataset.sortStatus = 'none'
      e.currentTarget.classList.remove('table-header__caption_min', 'table-header__caption_max')
    }

    const language = window.localStorage.getItem('language')
    renderContentTable(dataBase, language, contentData)
  }

  viewButtonBlock.addEventListener('click', () => {
    document.querySelector('[data-js="content-table"]').innerHTML = ''
    document.querySelector('[data-js="sort-tools"]').style.display = 'none'

    const language = window.localStorage.getItem('language')
    renderContentBlocks(dataBase, language, contentData)

    view = 'blocks'
    viewButtonTable.classList.remove('content__view-icon_active')
    viewButtonBlock.classList.add('content__view-icon_active')
  })

  viewButtonTable.addEventListener('click', () => {
    document.querySelector('[data-js="content-blocks"]').innerHTML = ''
    document.querySelector('[data-js="sort-tools"]').style.display = 'flex'

    const language = window.localStorage.getItem('language')
    renderContentTable(dataBase, language, contentData)

    view = 'table'
    viewButtonBlock.classList.remove('content__view-icon_active')
    viewButtonTable.classList.add('content__view-icon_active')
  })

  sendDataButton.addEventListener('click', () => {
    sendData()
  })

  function sendData() {
    if (!document.getElementById('your-name').value) {
      return
    } else if (!document.getElementById('contact').value) {
      return
    } else if (!document.getElementById('policyCheck').checked) {
      return
    }

    const data = {
      type: 'base',
      name: document.getElementById('your-name').value,
      social: document.querySelector('[data-js="modal"] [data-contact-button]').dataset.contactButton,
      contact: document.getElementById('contact').value,
      id: currentAcc
    }

    fetch('https://accs.biz/api/order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(() => {
      document.getElementById('your-name').value = ''
      document.getElementById('contact').value = ''

      modal.style.display = 'none'
      modalInner.classList.remove('modal_active')
    })
    // eslint-disable-next-line no-console
    .catch(error => console.error('Error:', error))
  }

  function sendChatData() {
    if (!document.getElementById('your-name-chat').value) {
      return
    } else if (!document.getElementById('contact-chat').value) {
      return
    }

    const data = {
      type: 'chat',
      name: document.getElementById('your-name-chat').value,
      social: document.querySelector('[data-js="chat-modal"] [data-contact-button]').dataset.contactButton,
      contact: document.getElementById('contact-chat').value
    }

    fetch('https://accs.biz/api/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(() => {
      document.getElementById('your-name-chat').value = ''
      document.getElementById('contact-chat').value = ''

      chatModal.classList.remove('chat_active')
    })
    // eslint-disable-next-line no-console
    .catch(error => console.error('Error:', error))
  }

  function filter(params) {
    const toggleFilterBlockButton = document.querySelector('[data-js="toggle-filter"]')
    const filterStatusInput = document.getElementById('filter-radio-button')
    const filterBlock = document.querySelector('[data-js="filter"]')
    const filterClearButton = document.querySelector('[data-js="filter-clear"]')
    const filterCloseButton = document.querySelector('[data-js="filter-close"]')

    const rangePostKarma = document.getElementById('range-post-karma')
    const inputPostKarmaMin = document.getElementById('post-min')
    const inputPostKarmaMax = document.getElementById('post-max')

    const rangeCommentKarma = document.getElementById('range-comment-karma')
    const inputCommentKarmaMin = document.getElementById('comment-min')
    const inputCommentKarmaMax = document.getElementById('comment-max')

    const rangePrice = document.getElementById('range-price')
    const inputPriceMin = document.getElementById('price-min')
    const inputPriceMax = document.getElementById('price-max')

    toggleFilterBlockButton.addEventListener('click', () => {
      filterBlock.classList.toggle('filter_active')
      contentWrapper.classList.toggle('content__wrapper_filter-mod')
    })

    filterCloseButton.addEventListener('click', () => {
      filterBlock.classList.toggle('filter_active')
      contentWrapper.classList.toggle('content__wrapper_filter-mod')
      // toggleFilterBlockButton.checked = !toggleFilterBlockButton.checked
    })

    // PostKarma
    noUiSlider.create(rangePostKarma, {
      start: [params.post_karma_min, params.post_karma_max],
      connect: true,
      range: {
        min: params.post_karma_min,
        max: params.post_karma_max
      }
    })

    rangePostKarma.noUiSlider.on('update', function (values) {
      inputPostKarmaMin.value = Math.round(values[0])
      inputPostKarmaMax.value = Math.round(values[1])
      filterData()
    })

    inputPostKarmaMin.addEventListener('change', e => rangePostKarma.noUiSlider.set([e.currentTarget.value, null]))
    inputPostKarmaMax.addEventListener('change', e => rangePostKarma.noUiSlider.set([null, e.currentTarget.value]))

    // CommentKarma
    noUiSlider.create(rangeCommentKarma, {
      start: [params.comment_karma_min, params.comment_karma_max],
      connect: true,
      range: {
        min: params.comment_karma_min,
        max: params.comment_karma_max
      }
    })

    rangeCommentKarma.noUiSlider.on('update', function (values) {
      inputCommentKarmaMin.value = Math.round(values[0])
      inputCommentKarmaMax.value = Math.round(values[1])
      filterData()
    })

    inputCommentKarmaMin.addEventListener('change', e => rangeCommentKarma.noUiSlider.set([e.currentTarget.value, null]))
    inputCommentKarmaMax.addEventListener('change', e => rangeCommentKarma.noUiSlider.set([null, e.currentTarget.value]))

    // Price
    noUiSlider.create(rangePrice, {
      start: [params.price_min, params.price_max],
      connect: true,
      range: {
        min: Number(params.price_min),
        max: Number(params.price_max)
      }
    })

    rangePrice.noUiSlider.on('update', function (values) {
      inputPriceMin.value = Math.round(values[0])
      inputPriceMax.value = Math.round(values[1])
      filterData()
    })

    inputPriceMin.addEventListener('change', e => rangePrice.noUiSlider.set([e.currentTarget.value, null]))
    inputPriceMax.addEventListener('change', e => rangePrice.noUiSlider.set([null, e.currentTarget.value]))

    function filterData() {
      if (!contentData) {
        return
      }

      let hasHiddenItem = false

      contentData.forEach(el => {
        el.showItem = true

        if (!(Number(el.postKarma) >= inputPostKarmaMin.value && Number(el.postKarma) <= inputPostKarmaMax.value)) {
          el.showItem = false
          hasHiddenItem = true
        }

        if (!(Number(el.commentKarma) >= inputCommentKarmaMin.value && Number(el.commentKarma) <= inputCommentKarmaMax.value)) {
          el.showItem = false
          hasHiddenItem = true
        }

        if (!(Number(el.price) >= inputPriceMin.value && Number(el.price) <= inputPriceMax.value)) {
          el.showItem = false
          hasHiddenItem = true
        }
      })

      filterStatusInput.checked = hasHiddenItem

      const language = window.localStorage.getItem('language')
      if (dataBase) {
        if (view === 'table') {
          renderContentTable(dataBase, language, contentData)
        } else {
          renderContentBlocks(dataBase, language, contentData)
        }
      }
    }

    // clear button
    filterClearButton.addEventListener('click', () => {
      contentData.forEach(el => {
        el.showItem = true
      })

      rangePostKarma.noUiSlider.set([params.post_karma_min, params.post_karma_max])
      rangeCommentKarma.noUiSlider.set([params.comment_karma_min, params.comment_karma_max])
      rangePrice.noUiSlider.set([params.price_min, params.price_max])

      const language = window.localStorage.getItem('language')
      if (view === 'table') {
        renderContentTable(dataBase, language, contentData)
      } else {
        renderContentBlocks(dataBase, language, contentData)
      }
    })
  }

  initSelect('modal')
  initSelect('chat-modal')

  function initSelect(selector) {
    const modal = document.querySelector(`[data-js="${selector}"]`)
    const modalSocialListItems = modal.querySelectorAll('[data-contact-item]')

    modalSocialListItems.forEach(el => {
      el.addEventListener('click', () => {
        modal.querySelector('[data-contact-button] img').src = `./assets/icons/front/${el.dataset.contactItem}.svg`
        modal.querySelector('[data-contact-button]').dataset.contactButton = el.dataset.contactItem
        modal.querySelector('[data-contact-input]').placeholder = el.dataset.contactTitle
      })
    })
  }

  function chooseLang(data) {
    let language = window.localStorage.getItem('language')

    if (!language) {
      const currentUrl = window.location.href

      const url = new URL(currentUrl)
      const lang = url.searchParams.get('lang')

      if (lang) {
        window.localStorage.setItem('language', lang)
        language = lang
      } else {
        window.localStorage.setItem('language', 'ru')
        language = 'ru'
      }
    }

    document.querySelectorAll(`[data-flag="${language}"]`).forEach(el => el.classList.add('header__lang-flag_active'))
    renderPage(data, language)

    const langFlags = document.querySelectorAll('[data-flag]')
    langFlags.forEach(el => {
      el.addEventListener('click', e => {
        renderPage(data, e.currentTarget.dataset.flag)
        if (view === 'table') {
          renderContentTable(data, e.currentTarget.dataset.flag, contentData)
        } else if (view === 'blocks') {
          renderContentBlocks(dataBase, e.currentTarget.dataset.flag, contentData)
        }

        window.localStorage.setItem('language', e.currentTarget.dataset.flag)

        langFlags.forEach(el => el.classList.remove('header__lang-flag_active'))
        document.querySelectorAll(`[data-flag="${e.currentTarget.dataset.flag}"]`).forEach(el => el.classList.add('header__lang-flag_active'))
      })
    })
  }

  function renderPage(data, lang) {
    const dataArray = data.localization[lang]
    const menu = document.querySelectorAll('[data-local="menu"]')
    const filterFields = document.querySelectorAll('[data-local="filter"]')
    const table = document.querySelectorAll('[data-local="table"]')
    const modalItems = document.querySelectorAll('[data-local="modal"]')
    const socialsList = document.querySelectorAll('[data-local="socials"]')
    const policy = document.querySelectorAll('[data-local="policy"]')
    const footer = document.querySelectorAll('[data-local="footer"]')

    document.title = dataArray.title

    sortButton.querySelectorAll('option').forEach((el, ind) => {
      el.innerHTML = dataArray.techVariables.sort[ind]
    })
    socialsList.forEach(list => list.querySelectorAll('li[data-contact-item]').forEach((el, ind) => {
      el.dataset.contactTitle = dataArray.socials[ind]
    }))

    change(menu, dataArray.menu)
    change(filterFields, dataArray.filter)
    change(table, dataArray.table)
    change(policy, dataArray.policy)
    change(footer, dataArray.footer)

    document.querySelectorAll('[data-contact-button] img').forEach(el => {
      el.src = './assets/icons/front/Telegram.svg'
    })
    document.querySelectorAll('[data-contact-button]').forEach(el => {
      el.dataset.contactButton = 'Telegram'
    })

    modalItems.forEach((el, ind) => {
      if (el.classList.contains('modal-form__item-input')) {
        el.placeholder = dataArray.modal[ind]
      } else {
        el.innerHTML = dataArray.modal[ind]
      }
    })

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function change(elements, text) {
      elements.forEach((el, ind) => {
        el.innerHTML = text[ind]
      })
    }
  }

  function renderContentTable(data, lang, itemsData) {
    const dataBase = data.localization[lang]
    const items = itemsData
    const table = document.querySelector('[data-js="content-table"]')

    table.innerHTML = ''
    items.forEach(el => {
      // if (el.status !== 'Ok') {
      //   return
      // }

      if (!el.showItem) {
        return
      }

      const tableItem = document.createElement('div')
      tableItem.classList.add('content__table-item')

      let description
      // eslint-disable-next-line unicorn/prefer-switch
      if (lang === 'ru') {
        description = `${window.innerWidth > 576 ? `Username: ${el.name}. ` : ''}Post karma: ${el.postKarma}. Comment karma: ${el.commentKarma}. Цена: ${el.price}$. Дата регистрации: ${el.cakeDay ? el.cakeDay.slice(0, 10) : 'не указано'}. Подтвержден по почте: ${el.emailVerif == 'Yes' ? 'да' : 'нет'}. CQS: ${el.cqs}. Proxy: ${el.typeProxy}`
      } else if (lang === 'ua') {
        description = `${window.innerWidth > 576 ? `Username: ${el.name}. ` : ''}Post karma: ${el.postKarma}. Comment karma: ${el.commentKarma}. Вартість: ${el.price}$. Дата реєстрації: ${el.cakeDay ? el.cakeDay.slice(0, 10) : 'не вказано'}. Підтвердження по пошті: ${el.emailVerif == 'Yes' ? 'так' : 'ні'}. CQS: ${el.cqs}. Proxy: ${el.typeProxy}`
      } else if (lang === 'eng') {
        description = `${window.innerWidth > 576 ? `Username: ${el.name}. ` : ''}Post karma: ${el.postKarma}. Comment karma: ${el.commentKarma}. Price: ${el.price}$. Cake date: ${el.cakeDay ? el.cakeDay.slice(0, 10) : 'not specified'}. Verified by mail: ${el.emailVerif == 'Yes' ? 'Yes' : 'no'}. CQS: ${el.cqs}. Proxy: ${el.typeProxy}`
      }

      tableItem.innerHTML = `
      <div class="table-item__column">
        <div class="table-item__id">${el.id}</div>
        <div class="table-item__logo">
          <img src="${el.avatar}" alt="avatar">
        </div>
        <div class="table-item__name">
          ${el.name}
        </div>
        <div class="table-item__description">
          <div class="table-item__description-text">
            ${el.bestseller ? '<span class="table-item__description-span">BESTSELLER</span>' : ''}
            ${description}
          </div>
        </div>
      </div>
      <div class="table-item__column">
        <div class="table-item__numbers">
          <span data-bs-toggle="tooltip" title="Post Karma">${el.postKarma}</span>
          <span data-bs-toggle="tooltip" title="Comment Karma">${el.commentKarma}</span>
          <span data-bs-toggle="tooltip" title="${dataBase.techVariables.priceTooltip}">${el.price}$</span>
        </div>
        <button data-js="item-buy" class="table-item__cart-button"><span>${dataBase.techVariables.buyButton}</span><img src="./assets/icons/front/icon-cart.svg" alt="cart"></button>
      </div>`

      tableItem.addEventListener('click', event => {
        if (event.target.dataset.js === 'item-buy') {
          modal.style.display = 'flex'
          setTimeout(() => modalInner.classList.add('modal_active'), 100)

          // modal.querySelector('.modal__description').innerHTML = description
          modal.querySelector('.modal__description').innerHTML = window.innerWidth > 576 ? description : `Username: ${el.name}. ${description}`
          modal.querySelector('.modal__total-value').textContent = `${el.price}$`
          currentAcc = el.id
        }
      })

      table.append(tableItem)
    })

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  function renderContentBlocks(data, lang, itemsData) {
    const dataBase = data.localization[lang]
    const blocks = document.querySelector('[data-js="content-blocks"]')
    blocks.innerHTML = ''

    itemsData.forEach(el => {
      // if (el.status !== 'Ok') {
      //   return
      // }

      if (!el.showItem) {
        return
      }

      const blocksItem = document.createElement('div')
      blocksItem.classList.add('content-blocks__item')

      blocksItem.innerHTML = `
      <div class="content-blocks__item-id">${el.id}</div>
      <div class="content-blocks__item-img">
        <img src="${el.avatar}" alt="avatar">
      </div>
      <div class="content-blocks__item-name">
        <span>${el.name}</span>
        ${el.adult == 'Yes' ? '<svg aria-label="nsfw" fill="#d93801" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" style="margin-left: 8px;"><path d="M13 10.967a1.593 1.593 0 0 0-1.363 0 1.2 1.2 0 0 0-.475.414 1.02 1.02 0 0 0-.173.576.967.967 0 0 0 .18.574c.122.172.29.307.482.393.21.095.438.143.668.14a1.51 1.51 0 0 0 .671-.146 1.2 1.2 0 0 0 .475-.4.985.985 0 0 0 .173-.569 1.024 1.024 0 0 0-.17-.57 1.2 1.2 0 0 0-.469-.412Z"></path><path d="M11.747 9.227c.177.095.374.143.574.14.2.003.396-.045.572-.14a1.057 1.057 0 0 0 .402-1.462.984.984 0 0 0-.406-.37 1.317 1.317 0 0 0-1.137 0 1 1 0 0 0-.557.902 1.047 1.047 0 0 0 .551.932l.001-.002Z"></path><path d="M18.636 6.73 13.27 1.363a4.634 4.634 0 0 0-6.542 0L1.364 6.73a4.627 4.627 0 0 0 0 6.542l5.365 5.365a4.633 4.633 0 0 0 6.542 0l5.366-5.365a4.634 4.634 0 0 0 0-6.542ZM8.204 14.5H6.288V8.277L4.648 9V7.23l2.988-1.367h.568V14.5Zm6.862-1.148c-.29.4-.683.714-1.136.912a4.11 4.11 0 0 1-3.24-.006 2.8 2.8 0 0 1-1.134-.916 2.172 2.172 0 0 1-.41-1.283c0-.42.12-.83.345-1.184a2.6 2.6 0 0 1 .944-.879 2.488 2.488 0 0 1-.636-.832c-.152-.32-.23-.67-.229-1.025a2.117 2.115 0 0 1 .378-1.248c.256-.362.604-.65 1.008-.832.43-.198.9-.298 1.374-.293.464-.004.942.099 1.371.3.403.182.749.47 1 .834.249.368.368.804.37 1.248a2.371 2.371 0 0 1-.868 1.851c.383.21.708.51.944.877a2.24 2.24 0 0 1-.074 2.481l-.007-.003Z"></path></svg>' : ''}
      </div>
      <div class="content-blocks__item-info">
        <div class="content-blocks__info-col">
          <span>Post Karma</span>
          <div>${el.postKarma}</div>
        </div>
        <div class="content-blocks__info-col">
          <span>Comment Karma</span>
          <div>${el.commentKarma}</div>
        </div>
        <div class="content-blocks__info-col content-blocks__info-col_cakeday">
          <span>
            <svg class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium css-1ns8gjm" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CakeIcon"><path d="M12 6c1.11 0 2-.9 2-2 0-.38-.1-.73-.29-1.03L12 0l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2m4.6 9.99-1.07-1.07-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07C6.75 16.64 5.88 17 4.96 17c-.73 0-1.4-.23-1.96-.61V21c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-4.61c-.56.38-1.23.61-1.96.61-.92 0-1.79-.36-2.44-1.01M18 9h-5V7h-2v2H6c-1.66 0-3 1.34-3 3v1.54c0 1.08.88 1.96 1.96 1.96.52 0 1.02-.2 1.38-.57l2.14-2.13 2.13 2.13c.74.74 2.03.74 2.77 0l2.14-2.13 2.13 2.13c.37.37.86.57 1.38.57 1.08 0 1.96-.88 1.96-1.96V12C21 10.34 19.66 9 18 9"></path></svg>
            Cake Day
          </span>
          <div>${el.cakeDay ? el.cakeDay.slice(0, 10) : '00-00-00'}</div>
        </div>
      </div>
      <div class="content-blocks__item-achievements">
        <div class="content-blocks__achievements-info">
          <div class="content-blocks__achievements-icons">
            <img data-bs-toggle="tooltip" title="Verified email included" src="./assets/icons/front/verif.png" alt="achievements">
            <img data-bs-toggle="tooltip" title="Cookies included" src="./assets/icons/front/cookie-icon.png" alt="achievements">
            <img data-bs-toggle="tooltip" title="CQS is Moderate" src="./assets/icons/front/mod-icon.png" alt="achievements">
            <img data-bs-toggle="tooltip" title="ADS Power profile" src="./assets/icons/front/ads-icon.png" alt="achievements">
          </div>
          <div class="content-blocks__achievements-text">
            <div>Verified email included</div>
            <div>Cookies included</div>
            <div>CQS is Moderate</div>
            <div>ADS Power profile</div>
          </div>
        </div>
      </div>
      <div class="content-blocks__item-price">
        ${el.price}$
      </div>
      <button data-js="item-buy" class="content-blocks__item-button table-item__cart-button"><span>${dataBase.techVariables.buyButton}</span><img src="./assets/icons/front/icon-cart.svg" alt="cart"></button>`

      let description
      // eslint-disable-next-line unicorn/prefer-switch
      if (lang === 'ru') {
        description = `Username: ${el.name}. Post karma: ${el.postKarma}. Comment karma: ${el.commentKarma}. Цена: ${el.price}$. Дата регистрации: ${el.cakeDay ? el.cakeDay.slice(0, 10) : 'не указано'}. Подтвержден по почте: ${el.emailVerif == 'Yes' ? 'да' : 'нет'}. CQS: ${el.cqs}. Proxy: ${el.typeProxy}`
      } else if (lang === 'ua') {
        description = `Username: ${el.name}. Post karma: ${el.postKarma}. Comment karma: ${el.commentKarma}. Вартість: ${el.price}$. Дата реєстрації: ${el.cakeDay ? el.cakeDay.slice(0, 10) : 'не вказано'}. Підтвердження по пошті: ${el.emailVerif == 'Yes' ? 'так' : 'ні'}. CQS: ${el.cqs}. Proxy: ${el.typeProxy}`
      } else if (lang === 'eng') {
        description = `Username: ${el.name}. Post karma: ${el.postKarma}. Comment karma: ${el.commentKarma}. Price: ${el.price}$. Cake date: ${el.cakeDay ? el.cakeDay.slice(0, 10) : 'not specified'}. Verified by mail: ${el.emailVerif == 'Yes' ? 'yes' : 'no'}. CQS: ${el.cqs}. Proxy: ${el.typeProxy}`
      }

      blocksItem.addEventListener('click', event => {
        if (event.target.dataset.js === 'item-buy') {
          modal.style.display = 'flex'
          setTimeout(() => modalInner.classList.add('modal_active'), 100)

          modal.querySelector('.modal__description').innerHTML = description
          modal.querySelector('.modal__total-value').textContent = `${el.price}$`
          currentAcc = el.id
        }
      })

      blocks.append(blocksItem)
    })

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }
})

function getData(url, param) {
  return fetch(url, param)
  .then(response => response.json())
  .catch(error => {
    // eslint-disable-next-line no-console
    console.log(error)
  })
}
